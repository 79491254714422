import { appConfig } from '../config/appConfig';
import { createHeaders } from '../utilities/helper';
const BASE_URL = appConfig.api.baseUrl;

const apiService = {
  get: async (endpoint: string, token: any) => {
    const response = await fetch(BASE_URL + `/${endpoint}`, {
      headers: createHeaders(token)
    });
    switch (response.status) {
      case 200:
      case 201:
        const data = await response.json();
        return data;
      case 400:
      case 403:
        throw new Error('Client Error: There was an issue with your request.');
      case 500:
        throw new Error('Server Error: There was an issue with the server.');
      case 401:
        throw new Error('UNAUTHORIZED');
      default:
        throw new Error(`Unexpected Error: Unexpected error Occured`);
    }
  },
  post: async (endpoint: string, payload: Object, token: any) => {
    const response = await fetch(BASE_URL + `/${endpoint}`, {
      method: 'POST',
      headers: createHeaders(token),
      body: JSON.stringify(payload)
    });

    switch (response.status) {
      case 200:
      case 201:
        const data = await response.json();
        return data;
      case 400:
        throw new Error(
          'Bad Request: The response was filtered due to the prompt triggering content management policy. Please modify your prompt and retry.'
        );
      case 403:
        const { name } = await response.json();
        throw new Error(
          `Permission Denied: You do not have permission to query the endpoint. Name: ${name}`
        );
      case 500:
        throw new Error('Server Error: There was an issue with the server');
      case 401:
        throw new Error('UNAUTHORIZED');
      default:
        throw new Error(`Unexpected Error: Unexpected error Occured`);
    }
  }
};

export const fetchApplicationsData = (token: any) => {
  const data = apiService.get('application/', token);
  return data;
};
export const fetchModelUsage=(token:any)=>{
  const data=apiService.get('model_usage/',token);
  return data
}
export const fetchRaiApplicationsData = (token: any) => {
  const data = apiService.get('application/?rai=true', token);
  return data;
};

export const fetchModelsData = (appid: any, token: any) => {
  const data = apiService.get(`model/?client_id=${appid}`, token);
  return data;
};

export const fetchModelStatus = (token: any) => {
  const data = apiService.get('application_model/', token);
  return data;
};

export const fetchKPIS = ({ appID, modelID }: any, token: any) => {
  let data;

  if (appID && !modelID) data = apiService.get(`llm_ops_dashboard/?application_id=${appID}`, token);
  else if (modelID && !appID)
    data = apiService.get(`llm_ops_dashboard/?model_id=${modelID}`, token);
  else {
    data = apiService.get(`llm_ops_dashboard/`, token);
  }
  return data;
};

export const fetchGraphDetails = ({ modelID }: any, token: any) => {
  let data;
  if (!modelID) {
    data = apiService.get(`metrics/`, token);
  } else if (modelID) {
    data = apiService.get(`metrics/?model_id=${modelID}`, token);
  }
  return data;
};

export const fetchLeaderboardData = (token: any) => {
  const data = apiService.get('leaderboard/', token);
  return data;
};

export const fetchAllPrompt = (token: any) => {
  const data = apiService.get('prompt_lib/', token);
  return data;
};

export const fetchPromptById = (id: string, token: any) => {
  const data = apiService.get(`prompt_lib/?prompt_id=${id}`, token);
  return data;
};

export const postUserQuery = (query: Object, token: any) => {
  const data = apiService.post(`prompt_lib/`, query, token);
  return data;
};
export const createPrompt = (query: Object, token: any) => {
  const data = apiService.post(`prompt/`, query, token);
  return data;
};

export const fetchRaiModelStatus = async (token: any) => {
  const data = apiService.get('model_status/', token);
  return data;
};

export const fetchRaiKPIs = async ({ appId }: any, token: any) => {
  let data;
  if (appId) {
    data = apiService.get(`rai_usage_metrics/?application_id=${appId}`, token);
  } else {
    data = apiService.get('rai_usage_metrics/', token);
  }

  return data;
};

export const fetchRAIBatchMetrics = ({ appId }: any, token: any) => {
  let data;
  if (appId) {
    data = apiService.get(`rai_batch_metrics/?application_id=${appId}`, token);
    return data;
  } else throw new Error('App id required');
};

export const fetchRAISafetyMetrics = ({ appId }: any, token: any) => {
  let data;
  if (appId) {
    data = apiService.get(`rai_safety_metrics/?application_id=${appId}`, token);
    return data;
  } else throw new Error('App id required');
};

export const postPlayground = (query: any, token: any) => {
  const data = apiService.post(`playground/`, query, token);
  return data;
};

export const fetchAllModels = (token: any) => {
  const data = apiService.get('playground/', token);
  return data;
};

export const fetchAdminCheck = (token: any) => {
  const data = apiService.get('admin/', token);
  return data;
};

export const fetchCostforApps = ({ startDate, endDate, appId }: any, token: any) => {
  let data;

  if (startDate && endDate) {
    if (appId) {
      data = apiService.get(
        `llm_usage_cost/?startDate=${startDate}&endDate=${endDate}&client_id=${appId}`,
        token
      );
    } else
      data = apiService.get(`llm_usage_cost/?startDate=${startDate}&endDate=${endDate}`, token);
  }
  return data;
};
export const postFeedback = (feedback: any, token: any) => {
  const data = apiService.post('feedback/', feedback, token);
  return data;
};

export const fetchPromptModels = (appId: any, token: any) => {
  const data = apiService.get(`prompt_app_models/?client_id=${appId}`, token);
  return data;
};
