export const errorMessages = {
  serverError: 'Something Went wrong',
  emptyValue: 'No data Found',
  aceesIssue: 'You are not allowed to access the model',
  tokenExpired: 'Your session has expired. Please refresh the page and try again.'
}
export const envVariables = {
  dev: {
    apiBaseUrl: 'https://bnlwe-ai04-d-930633-webapi-03.azurewebsites.net',
    hostname: 'bnlwe-ai04-d-930633-webapi-02.azurewebsites.net',
    clientId: '1c8be9a0-b452-44f9-87c8-2e324a221e94',
    scopesToConsent: 'api://8c38a317-025f-4aaf-81a4-1596627f4941/access'
  },
  qa: {
    apiBaseUrl: 'https://bnlwe-ai03-q-931039-webapi-03.azurewebsites.net',
    hostname: 'bnlwe-ai03-q-931039-webapi-02.azurewebsites.net',
    clientId: '9d2662be-6d93-4c89-8fa0-38b48896aa9a',
    scopesToConsent: 'api://2ae81fb9-36f9-48a8-9e44-a3c213588aa5/access'
  },
  prod: {
    apiBaseUrl: 'https://bnlwe-ai01-p-930632-webapi-03.azurewebsites.net',
    hostname: 'bnlwe-ai01-p-930632-webapi-02.azurewebsites.net',
    clientId: '2c14aa0a-7463-4a27-8e4f-359646cb0e1d',
    scopesToConsent: 'api://434c1d1b-608e-4c80-8551-922381799d6e/access'
  }
}
export const pages = [
  { id: 1, name: 'Dashboard', path: '/dashboard' },
  { id: 2, name: 'Responsible AI', path: '/rai' },
  { id: 3, name: 'Leaderboard', path: '/leaderboard' },
  { id: 4, name: 'Prompt Library', path: '/promptlibrary' },
  { id: 5, name: 'Prompt Playground', path: '/promptplayground' },
  { id: 6, name: 'FinOps', path: '/finops' },
  
];

export const unileverColor = {
  green: {
    rgb: 'rgba(97, 212, 196, 1)',
    hex: '#61D4C4'
  },
  pink: {
    rgb: 'rgba(255, 121, 198, 1)',
    hex: '#FF79C6'

  },
  blue: {
    rgb: 'rgba(143, 202, 235, 1)',
    hex: '#8FCAEB'
  },
  purple: {
    rgb: 'rgba(119, 5, 188, 1)',
    hex: '#7705BC'
  },
  violet: {
    rgb: 'rgba(132, 108, 226, 1)',
    hex: '#846CE2'
  },
  plum: {
    rgb: 'rgba(199, 154, 213, 1)',
    hex: '#C79AD5'
  },
  rose: {
    rgb: 'rgba(239, 88, 145, 1)',
    hex: '#EF5891'
  },
  orange: {
    rgb: 'rgba(241, 163, 58, 1)',
    hex: '#F1A33A'
  },
  yellow: { rgb: 'rgba(255, 227, 0, 1)', hex: '#FFE300' },
  cream: {
    rgb: 'rgba(252, 237, 164, 1)',
    hex: '#FCEDA4'
  },
  grey: {
    rgb: 'rgba(199, 199, 199, 1)',
    hex: '#C7C7C7'
  },
  red: {
    rgb: 'rgba(236, 97, 87, 1)',
    hex: '#EC6157'
  },
  amber: {
    rgb: 'rgba(255, 221, 55, 1)',
    hex: '#FFDD37'
  },


};






