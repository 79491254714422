import { useEffect, useState } from 'react';
import { fetchAllModels, postPlayground } from '../service/apiServices';
import { useAzureData } from '../context/AzureContext';
import Multiselect from 'multiselect-react-dropdown';
import PlaceHolder from './PlaceHolder';
import { errorMessages } from '../utilities/constants';
import parse from 'html-react-parser';
import { usePromptPlaygroundContext } from '../context/PromptPlayGroundContext';

const PromptPlayground = () => {
  const { apiToken } = useAzureData();
  // const [models, setModels] = useState<any[] | null>(null);
  const [selectedModels, setSelectedModels] = useState<any[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [userPrompt, setuserPrompt] = useState('');
  const [modelResponse, setModelResponse] = useState<any | null>(null);
  const [disabledVal, setDisabled] = useState<any | null>(null);
  const { models, error, isLoading } = usePromptPlaygroundContext();
  const onSelect = async (selectedList: any) => {
    setSelectedModels(selectedList);
    if (selectedList.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };
  const onRemove = async (selectedList: any) => {
    setSelectedModels(selectedList);
    if (selectedList.length === 0) {
      setDisabled(true);
    }
  };
  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setDisabled(true);
    setErrorMessage(null);
    setModelResponse('');
    const playGroundPostData = selectedModels;
    playGroundPostData?.forEach((element) => {
      element.prompt = userPrompt;
    });
    const submitPromptData = async () => {
      try {
        const response = await postPlayground(playGroundPostData, apiToken);
        setModelResponse(response);
        setDisabled(false);
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    };
    if (playGroundPostData && playGroundPostData?.length > 0) {
      submitPromptData();
    }
  };

  const parseResponse = (response: any) => {
    let output;
    if (response.response_data.choices) {
      if (response.response_data.choices[0].message?.content) {
        output = response.response_data.choices[0]?.message?.content.replace(/\*/g, '');
      } else {
        output = response.response_data.choices[0]?.text.replace(/\*/g, '');
      }
    } else if (response.response_data.predictions[0].response !== null) {
      output = response.response_data.predictions[0].response;
    } else {
      output = 'NA';
    }
    return parse(output);
  };
  const convertData = (data: any) => {
    if (data) {
      return data;
    } else {
      return '';
    }
  };
  const convertDataInputSafety = (data: any) => {
    if (data) {
      return data[0].content_filter_results;
    } else {
      return '';
    }
  };
  const safetyCheck = (data: any) => {
    const entries = Object.entries(data);
    let safetyStatus = true;
    entries.forEach((entry: any, index) => {
      if (entry[1].filtered === true) {
        safetyStatus = false;
      }
    });
    return safetyStatus ? true : safetyStatus;
  };
  const formatString = (data: any) => {
    return data === true || data === null ? 'Safe' : 'UnSafe';
  };
  const renderResponseModels = () => {
    let nameMatch;
    if (errorMessage?.includes('Permission')) {
      nameMatch = errorMessage.match(/Name: ([^\s]+)/);

      return (
        <PlaceHolder
          message={`You do not have access to the ${
            nameMatch ? nameMatch[1] : 'selected'
          } model. Kindly select different model/models`}
        />
      );
    }
    return (
      <div className="row g-2 pt-4 mb-3 text-left">
        {modelResponse &&
          modelResponse[0]?.status_code !== 400 &&
          modelResponse.length > 0 &&
          modelResponse.map((response: any) => (
            <div className="col-md-4" key={response.model_id || response.response_data.id}>
              {response.error_code ? (
                <>
                  <div className="card rounded small-box">
                    <div className="row d-flex justify-content-center">
                      <div className="mt-1 row d-flex justify-content-center">
                        <h5 className="prompt-playground-card-heading text-center">
                          {response.model_name}
                        </h5>
                      </div>
                      <div className="card-body mx-2">
                        <div className="mb-2 row d-flex justify-content-center">
                          You don't have acces to this model
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="card rounded small-box">
                  <div className="row d-flex justify-content-center">
                    <div className="mt-1 row d-flex justify-content-center">
                      <h5 className="prompt-playground-card-heading text-center">
                        {response.response_data.model
                          ? response.response_data.model
                          : response.model}
                      </h5>
                    </div>
                    <div className="card-body mx-2">
                      <div className="mb-2 row d-flex justify-content-center">
                        <span
                          className="badge bg-transparent mt-2 border border-white rounded-0 fw-normal"
                          style={{ width: '118px' }}>
                          Response
                        </span>
                      </div>
                      <div style={{ whiteSpace: 'pre-line' }}>{parseResponse(response)}</div>
                      <div className="mt-2 mb-2 row d-flex justify-content-center">
                        <span
                          className="badge bg-transparent mt-2 border border-white rounded-0 fw-normal"
                          style={{ width: '118px' }}>
                          Model Performance
                        </span>
                      </div>
                      <h6 className="my-0 mb-1" style={{ fontWeight: '600' }}>
                        Metrics
                      </h6>
                      <div className="row">
                        <div className="col text-left">
                          Input Safety:{' '}
                          <strong>
                            {response.response_data.usage
                              ? formatString(
                                  safetyCheck(
                                    convertDataInputSafety(
                                      response.response_data.prompt_filter_results
                                    )
                                  )
                                )
                              : formatString(response.response_data.predictions[0].input_safety)}
                          </strong>
                        </div>
                        <div className="col text-left">
                          Output Safety:{' '}
                          <strong>
                            {response.response_data.usage
                              ? formatString(
                                  safetyCheck(
                                    convertData(
                                      response.response_data.choices[0].content_filter_results
                                    )
                                  )
                                )
                              : formatString(response.response_data.predictions[0].output_safety)}
                          </strong>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-left">
                          No. of Tokens In:{' '}
                          <strong>
                            {response.response_data.usage
                              ? response.response_data.usage.prompt_tokens
                              : response.response_data.predictions[0].input_token_count}
                          </strong>
                        </div>
                        <div className="col text-left">
                          No. of Tokens Out:{' '}
                          <strong>
                            {response.response_data.usage
                              ? response.response_data.usage.completion_tokens
                              : response.response_data.predictions[0].output_token_count}
                          </strong>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-left">
                          Request Latency:{' '}
                          <strong>
                            {response.response_data.usage
                              ? response.latency.toFixed(2) + ' Secs'
                              : response.response_data.predictions[0].latency.toFixed(2) + ' Secs'}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
    );
  };

  if (errorMessage?.includes('Server Error') || error)
    return <PlaceHolder message={errorMessages.serverError} />;
  else if (errorMessage?.includes('UNAUTHORIZED'))
    return <PlaceHolder message={parse(errorMessages.tokenExpired)} />;
  else if (isLoading)
    return (
      <div
        style={{ height: '100vh' }}
        className="container-fluid d-flex justify-content-center align-items-center">
        <h4 className="text-center">Loading...</h4>
      </div>
    );
  else
    return (
      <div className="card mt-4 prompt-playground">
        <div className="card-body">
          <form className="row g-3" onSubmit={handleSubmit}>
            <div className="col-md-7 mx-auto">
              <div>
                <label htmlFor="inputAddress" className="form-label">
                  Models Selection
                </label>
                {models && (
                  <Multiselect
                    options={models}
                    showCheckbox={true}
                    showArrow={true}
                    onSelect={onSelect}
                    onRemove={onRemove}
                    displayValue="model_name"
                    selectionLimit="3"
                  />
                )}
              </div>
            </div>
            <div className="col-md-7 mx-auto">
              <label htmlFor="userPrompt" className="form-label">
                User Prompt
              </label>
              <textarea
                required
                className="form-control"
                id="userPrompt"
                name="userPrompt"
                rows={5}
                value={userPrompt}
                onChange={(event) => setuserPrompt(event.target.value)}></textarea>
            </div>
            {modelResponse && modelResponse.length > 0 && modelResponse[0]?.status_code === 400 && (
              <div className="col-12 d-flex justify-content-center text-danger">
                Bad Request: Please modify your prompt and retry.
              </div>
            )}
            <div className="col-12 d-flex justify-content-center">
              <button
                type="submit"
                className="btn btn-primary uniBtnPurple"
                disabled={!selectedModels || disabledVal}>
                Submit
              </button>
            </div>
          </form>
          {renderResponseModels()}
        </div>
      </div>
    );
};

export default PromptPlayground;
