import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';

import { fetchApplicationsData, fetchCostforApps } from '../service/apiServices';
import { useAzureData } from '../context/AzureContext';
import PlaceHolder from './PlaceHolder';
import parse from 'html-react-parser';
import { errorMessages } from '../utilities/constants';
import './FinOps.css';
import { downloadExcel, sumOfCost } from '../utilities/helper';
import download from '../assets/icon/download.svg';
import close from '../assets/icon/close-button.svg';
import { Graph } from './Graph';
type App = {
  application_id: number;
  application_name: string;
};

type Records = {
  application_id: number;
  application_name: string;
  client_request_id: string;
  model_id: string;
  total_cost: string;
  total_tokens: string;
  usage_date: string;
  model_name: string;
  client_name: string;
};
type AppCost = {
  records: Records[];
};
const FinOps = () => {
  let serialNumber = 0;
  const today = new Date();
  const lastDate = new Date();
  lastDate.setDate(today.getDate() - 1);
  const aweekAgo = new Date();
  aweekAgo.setDate(today.getDate() - 7);
  const [startDate, setStartDate] = useState<Date>(aweekAgo);
  const [endDate, setEndDate] = useState<Date>(lastDate);
  const [startDateFormatted, setStartDateFormatted] = useState(
    aweekAgo.toLocaleDateString('en-CA')
  );
  const [endDateFormatted, setEndDateFormatted] = useState(lastDate.toLocaleDateString('en-CA'));
  const [applicationData, setApplicationData] = useState<App[] | null>(null);
  const [selectedApp, setSelectedApp] = useState(null);
  const [appCost, setAppCost] = useState<AppCost | null>(null);
  const [showTableAnalysis, setShowTableAnalysis] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const { apiToken, isAdmin } = useAzureData();
  const navigate = useNavigate();
  const fetchCostforAllApp = async () => {
    try {
      const result = await fetchCostforApps(
        { startDate: startDateFormatted, endDate: endDateFormatted },
        apiToken
      );
      setAppCost(result);
    } catch (error: any) {
      setErrorMessage(error);
    }
  };
  useEffect(() => {
    const fetchAppData = async () => {
      try {
        const response = await fetchApplicationsData(apiToken);
        setApplicationData(response);
      } catch (error: any) {
        setErrorMessage(error);
      }
    };
    if (!isAdmin) {
      navigate('/dashboard');
      return;
    }
    fetchAppData();
    fetchCostforAllApp();
  }, [apiToken]);

  useEffect(() => {
    const fetchCostforApp = async () => {
      try {
        const result = await fetchCostforApps(
          { startDate: startDateFormatted, endDate: endDateFormatted, appId: selectedApp },
          apiToken
        );
        setAppCost(result);
      } catch (error: any) {
        setErrorMessage(error);
      }
    };
    if (startDate && endDate && !selectedApp) fetchCostforAllApp();
    else if (selectedApp && selectedApp !== 'ALL') fetchCostforApp();
    else if (selectedApp && selectedApp === 'ALL') fetchCostforAllApp();
  }, [startDate, endDate, selectedApp]);

  const handleClick = () => {
    setShowTableAnalysis(true);
  };
  const handleClose = () => {
    setShowTableAnalysis(false);
  };
  const handleAppChange = (id: any) => {
    setSelectedApp(id);
  };

  const updateDateRange = (range: any) => {
    const today = new Date();
    let startDate, endDate;
    switch (range) {
      case 'last7days':
        startDate = new Date(today.setDate(today.getDate() - 7));
        endDate = new Date();
        setStartDate(startDate);
        setEndDate(endDate);
        setStartDateFormatted(startDate?.toLocaleDateString('en-CA'));
        setEndDateFormatted(endDate?.toLocaleDateString('en-CA'));
        break;

      case 'lastmonth':
        const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        startDate = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1);
        endDate = new Date(lastMonth.getFullYear(), lastMonth.getMonth() + 1, 0);
        setStartDate(startDate);
        setEndDate(endDate);
        setStartDateFormatted(startDate?.toLocaleDateString('en-CA'));
        setEndDateFormatted(endDate?.toLocaleDateString('en-CA'));
        break;
      case 'thismonth':
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date();
        setStartDate(startDate);
        setEndDate(endDate);
        setStartDateFormatted(startDate?.toLocaleDateString('en-CA'));
        setEndDateFormatted(endDate?.toLocaleDateString('en-CA'));
        break;
      default:
        return;
    }
  };
  const handleRangeSelection = (range: any) => {
    if (range === 'custom') {
      setShowDatePicker(true);
    } else {
      setShowDatePicker(false);
      updateDateRange(range);
    }
  };
  const handleDateRangeSelection = ([newStartDate, newEndDate]: any[]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setStartDateFormatted(newStartDate?.toLocaleDateString('en-CA'));
    setEndDateFormatted(newEndDate?.toLocaleDateString('en-CA'));
  };
 
  const renderRangePicker = () => {
    return (
      <>
        <select
          className="form-select"
          onChange={(e) => handleRangeSelection(e.target.value)}
          defaultValue="Select Range"
          style={{ minWidth: '17.5rem', fontSize: '0.875rem' }}>
          <option value="last7days">Last Seven Days</option>
          <option value="thismonth">This Month</option>
          <option value="lastmonth">Last Month</option>
          <option value="custom">Custom Date Range </option>
        </select>
        <div className="mb-2 mb-md-0 float-start form-group">
          {showDatePicker && <div className="py-2">{renderDatePicker()}</div>}
        </div>
      </>
    );
  };
  const renderDatePicker = () => {
    return (
      <DatePicker
        maxDate={lastDate}
        minDate={new Date('2024-10-01')}
        showIcon
        selected={endDate}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        onChange={handleDateRangeSelection}
        className="date-selector rounded-3"
      />
    );
  };
  const appWiseCost = appCost?.records?.map((app) => parseFloat(app.total_cost));

  const renderGraph = () => {
    appCost?.records?.sort(
      (a, b): any => new Date(a.usage_date).getDate() - new Date(b.usage_date).getDate()
    );
    const groupedDates = appCost?.records?.reduce((acc: any, { usage_date, total_cost }) => {
      const currentDate = new Date(usage_date);
      const startofGroup = new Date(currentDate);
      startofGroup.setDate(currentDate.getDate() - (currentDate.getDate() % 3));
      const formattedDate = startofGroup.toLocaleDateString();
      if (!acc[formattedDate]) {
        acc[formattedDate] = { date: formattedDate, totalCost: 0, count: 0 };
      }
      acc[formattedDate].totalCost += parseFloat(total_cost);
      acc[formattedDate].count += 1;
      return acc;
    }, {});
    const labels = Object.keys(groupedDates);

    const costs = labels.map((label) => groupedDates[label].totalCost / groupedDates[label].count);

    return (
      <div className="">
        <span>
          <Graph
            graphType="line"
            labelText="Cost Analysis"
            xData={labels}
            yData={costs}
            xLabel="Date"
            yLabel="Average Cost Over Time"
            dataSetLabel='Cost Vs Time'
          />
        </span>
      </div>
    );
  };
  const renderCostTable = () => (
    <div className="row g-2 row-cols-1 row-cols-md-1 mb-3 text-center">
      <div className="col">
        <div className="card mb-4 rounded-3 shadow-sm">
          <div className="card-header py-3">
            <div className="row">
              <div className="col-10 text-center">
                <h6>Cost By App</h6>
              </div>

              <div className="col-1">
                <img
                  src={download}
                  style={{ cursor: 'pointer' }}
                  height={30}
                  width={30}
                  alt="Download"
                  onClick={()=>downloadExcel(appCost)}
                />
              </div>

              <div className="col-1">
                <img
                  src={close}
                  style={{ cursor: 'pointer' }}
                  height={30}
                  width={30}
                  alt="Close"
                  onClick={handleClose}
                />
              </div>
            </div>
          </div>

          {appCost && appCost.records.length > 0 && (
            <div className="card-body table-responsive">
              <div className="table-container-cost">
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Application</th>
                      <th>Model</th>
                      <th>Usage Date</th>
                      <th>Cost</th>
                    </tr>
                  </thead>
                  <tbody>
                    {appCost?.records?.map((cost, index) => (
                      <tr key={serialNumber}>
                        <td>{++serialNumber}</td>
                        <td>{cost.client_name}</td>
                        <td>{cost.model_name}</td>
                        <td>{cost.usage_date}</td>
                        <td>{parseFloat(cost.total_cost)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td style={{ fontWeight: 'bold' }}>Total</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      {appWiseCost && (
                        <td style={{ fontWeight: 'bold' }}> {sumOfCost(appWiseCost)}</td>
                      )}
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
  if (errorMessage)
    return (
      <PlaceHolder
        message={parse(
          errorMessage.message === 'UNAUTHORIZED'
            ? errorMessages.tokenExpired
            : errorMessages.serverError
        )}
      />
    );

  return (
    <>
      <div className="d-flex justify-content-end flex-wrap flex-md-nowrap align-items-start pt-3 pb-2 mb-3">
        <div className="mb-2 mb-md-0 float-start mx-2 form-group">
          <p
            className="mb-1"
            style={{
              color: '#797979',
              fontSize: '0.875rem',
              fontWeight: 400,
              fontStyle: 'Unilever Shilling'
            }}>
            Select Date Range
          </p>

          {renderRangePicker()}
        </div>
        <div className="mb-2 mb-md-0 float-start mx-4 form-group">
          <p
            className="mb-1"
            style={{
              color: '#797979',
              fontSize: '0.875rem',
              fontWeight: 400,
              fontStyle: 'Unilever Shilling'
            }}>
            Select Application
          </p>
          <select
            defaultValue={'ALL'}
            className="form-select"
            style={{ minWidth: '17.5rem', fontSize: '0.875rem' }}
            onChange={(e) => handleAppChange(e.target.value)}>
            <option value="ALL">ALL</option>
            {applicationData?.map((data) => (
              <option key={data.application_id} value={data.application_id}>
                {data.application_name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {appCost && (
        <div className="row">
          <div className="col-md-6">{renderGraph()}</div>

          <div className="col-md-6 container">
            <div className="row btn-text-container">
              <div>{appWiseCost && <h4>Total Cost: {sumOfCost(appWiseCost)}</h4>}</div>
              <div>
                <button
                  className="btn btn-primary uniBtnPurple"
                  onClick={handleClick}
                  disabled={showTableAnalysis || appCost?.records.length === 0}>
                  View Table Analaysis
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div>{showTableAnalysis&&appCost?.records.length !== 0 && renderCostTable()}</div>
    </>
  );
};

export default FinOps;
